/* Styles pour la barre de navigation avec logo agrandi */

.header {
  background-color: #f0f0f0;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure dropdown is positioned correctly */
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav ul li {
  margin-right: 30px;
  position: relative; /* Ensure the dropdown appears relative to the nav item */
}

.nav ul li:last-child {
  margin-right: 0;
}

.nav ul li a {
  text-decoration: none;
  color: #333;
  padding: 12px 16px;
}

.nav ul li.active {
  background-color: #ddd;
}

.nav ul li a:hover {
  background-color: #ddd;
}

.logo {
  height: 60px;
  width: auto;
}
