.africa-banner {
    display: flex;
    align-items: center;
    padding: 20px;

    width: 100%;
    height: 400px;
  }
  
  .africa-text {
    flex: 1;
    padding-right: 20px;
  }
  
  .africa-text h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333; /* Couleur sombre pour le texte */
  }
  
  .africa-text p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666; /* Couleur plus claire pour le texte */
  }
  
  .africa-image {
    flex: 1;
    text-align: right;
    height: 400px;
  }
  
  .africa-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Bordures arrondies pour un look moderne */
  }
  