/* Style général pour la section hero */
.hero-section {
  width: 100%;
  height: 500px; /* Hauteur de la section hero */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  overflow: hidden; /* Pour éviter que le contenu dépasse */
}

/* Fond semi-transparent pour améliorer la lisibilité du texte */
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Opacité ajustée pour un meilleur contraste */
  z-index: 1;
}

/* Positionnement relatif pour le texte */
.hero-text {
  position: relative;
  z-index: 2; /* Assure que le texte est au-dessus du fond */
  max-width: 90%; /* Pour éviter que le texte ne soit trop large */
  margin: 0 auto; /* Centre le texte horizontalement */
  padding: 20px; /* Espacement autour du texte */
  border-radius: 8px; /* Coins arrondis pour un look moderne */
}

/* Style pour le titre */
.hero-text h1 {
  font-size: 3rem; /* Taille du titre */
  margin-bottom: 20px; /* Marge sous le titre */
  font-weight: 700; /* Poids de la police pour le titre */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre pour améliorer la lisibilité */
}

/* Style pour le paragraphe */
.hero-text p {
  font-size: 1.5rem; /* Taille de la police du paragraphe */
  line-height: 1.6; /* Espacement entre les lignes pour la lisibilité */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Ombre pour le texte du paragraphe */
}
.info-section {
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box; /* Inclut padding et border dans la largeur totale */
}

.info-block {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  box-sizing: border-box; /* Inclut padding et border dans la largeur totale */
}

.info-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin: 2rem 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

.info-block h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Style général pour map-and-text */
.map-and-text {
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box; /* Inclut padding et border dans la largeur totale */
}

/* Style pour la carte */
.map-container {
  flex: 1;
  height: 400px; /* Ajustez la hauteur selon vos besoins */
  box-sizing: border-box; /* Inclut padding et border dans la largeur totale */
}

/* Style pour le texte à côté de la carte */
.map-text {
  flex: 1;
  padding-left: 20px; /* Espacement entre la carte et le texte */
  box-sizing: border-box; /* Inclut padding et border dans la largeur totale */
  font-family: 'Arial', sans-serif; /* Police de caractères */
  color: #333; /* Couleur sombre pour le texte */
  background-color: #f9f9f9; /* Fond légèrement gris pour contraster avec la carte */
  border-radius: 8px; /* Coins arrondis pour un look moderne */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre pour ajouter de la profondeur */
  padding: 20px; /* Padding pour ajouter de l'espace autour du texte */
  margin: 0 20px; /* Espacement horizontal pour éviter que le texte ne touche les bords */
}

/* Style pour le paragraphe */
.map-text p {
  font-size: 1.2rem; /* Taille de police légèrement plus grande */
  margin-bottom: 1rem; /* Marge sous le paragraphe */
  font-weight: 300; /* Poids de la police plus léger pour une lecture facile */
  line-height: 1.6; /* Espacement entre les lignes */
}

/* Style pour la liste */
.map-text ul {
  padding-left: 1.5rem; /* Espacement pour les puces */
  list-style: none; /* Supprime les puces par défaut */
  margin: 0; /* Supprime la marge par défaut */
}

/* Style pour les éléments de la liste */
.map-text li {
  font-size: 1.1rem; /* Taille de police légèrement plus grande */
  margin-bottom: 0.5rem; /* Marge sous chaque élément de la liste */
  position: relative; /* Position pour les puces personnalisées */
  padding-left: 1.5rem; /* Espacement pour les puces personnalisées */
}

/* Style pour les puces personnalisées */
.map-text li::before {
  content: "•"; /* Utilisation d'un caractère de puce personnalisé */
  color: #007bff; /* Couleur de la puce */
  font-size: 1.5rem; /* Taille de la puce */
  position: absolute; /* Positionnement absolu pour placer la puce */
  left: 0; /* Aligne la puce à gauche */
  top: 50%; /* Centre la puce verticalement */
  transform: translateY(-50%); /* Ajuste le centrage vertical */
}



.banner-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
}

/* Styles pour les éléments de bannière */
.banner-item {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 250px; /* Ajustez la hauteur selon vos besoins */
  color: #fff; /* Couleur du texte sur les images de fond */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease; /* Transition pour un effet de survol fluide */
}

.banner-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Couleur sombre avec opacité */
  transition: background 0.3s ease; /* Transition pour un effet de survol fluide */
  z-index: 1; /* Assurez-vous que l'élément de superposition est au-dessus de l'image */
}

.banner-item:hover::before {
  background: rgba(0, 0, 0, 0.6); /* Assombrir davantage au survol */
}

.banner-text {
  position: relative;
  z-index: 2; /* S'assurer que le texte est au-dessus de la superposition sombre */
  text-align: center; /* Centrer le texte si nécessaire */
  padding: 10px; /* Ajouter un padding pour espacer le texte des bords */
}

/* Ajustements pour le texte */
.banner-text h3 {
  margin: 0;
  font-size: 1.5rem; /* Ajustez selon vos besoins */
}

.banner-text p {
  margin: 5px 0 0;
  font-size: 1rem; /* Ajustez selon vos besoins */
}
