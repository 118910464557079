/* Styles pour la page de service */
.service-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 20px;
}

.service-section {
    background: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-title {
    color: #007BFF; /* Couleur du titre */
    font-size: 2.5em; /* Taille du titre */
    margin-bottom: 10px;
    text-align: center; /* Centrer le titre */
}

.service-intro {
    font-size: 1.2em; /* Taille du texte d’introduction */
    font-weight: bold; /* Mettre le texte en gras */
    text-align: center; /* Centrer le texte d’introduction */
    margin-bottom: 20px;
}

/* New grid layout for the content */
.service-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns for image and text */
    align-items: center;
    gap: 20px; /* Space between grid items */
    margin-top: 10px;
}

/* Styling the service image */
.service-image {
    width: 100%;
    height: 700px; /* Adjust this to maintain aspect ratio */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for better presentation */
}

/* Details section on the right */
.service-details {
    max-width: 600px; /* Largeur maximale pour le texte */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left; /* Align text to the left */
    margin-bottom: 10px ;
}

.service-details p {
    font-size: 16px; /* Taille du texte */
    margin-bottom: 10px; /* Reduced spacing between paragraph and list */
}

.service-details ul {
    list-style-type: none; /* Supprimer les puces par défaut */
    padding: 0; /* Supprimer le padding par défaut de la liste */
    margin-top: 0; /* Ensure there's no space between <p> and <ul> */
}

.service-details ul li {
    font-size: 18px; /* Taille agrandie du texte des éléments de la liste */
    margin-bottom: 10px; /* Adjusted spacing between list items */
    position: relative; /* Nécessaire pour le positionnement des puces personnalisées */
    padding-left: 1.5em; /* Espace pour les puces personnalisées */
}

.service-details ul li:before {
    content: "•";
    color: #007BFF; /* Couleur des puces */
    font-weight: bold;
    display: inline-block;
    width: 1em;
    position: absolute;
    left: 0; /* Positionner les puces directement à gauche du texte */
    top: 0; /* Aligner les puces avec le texte */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .service-content-grid {
        grid-template-columns: 1fr; /* Stacks the image and text on top of each other */
    }

    .service-details {
        text-align: center; /* Center the text for smaller screens */
    }
}

.features{
    margin-right: auto;
    margin-left: 0;
    margin-top: 10px;
    font-weight: bold;
}


.features-list{
    margin-right: auto;
    margin-left: 50px;
}