/* Styles pour le fond assombrissant */
.backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0; /* Hauteur ajustable pour couvrir seulement le bas */
  background-color: rgba(58, 57, 57, 0.3); /* Gris clair avec transparence */
  z-index: 1000;
  display: none; /* Caché par défaut */
}

.backdrop.show {
  display: block; /* Affiché lorsque le menu est actif */
}

/* Styles pour le menu déroulant */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Réduit la largeur du menu */
  border: 1px solid #000000;
  background-color: #f0f0f0;
  color: rgb(0, 0, 0);
  display: none;
  z-index: 1001;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-content {
  display: flex;
  padding: 20px;
}

.categories, .services {
  flex: 1;
  padding: 10px;
}

.categories ul, .services ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.categories ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligne à gauche */
}

.categories ul li {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligne à gauche */
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 8px 12px;
  border-radius: 4px;
}

/* Effet hover pour les catégories */
.categories ul li:hover {
  background-color: #e0e0e0;
  text-decoration: underline;

}

/* Effet sélection pour les catégories */
.categories ul li.active {
  padding: 8px 12px;
  background-color: #d4d4d4;
  text-decoration: underline;
  font-weight: bold;
  border-radius: 4px;
}

.categories ul li .icon {
  margin-right: 8px;
  font-size: 16px;
}

.categories ul li a {
  color: #000000;
}

.services {
  display: flex;
  flex-direction: column;
}

.services ul li a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  display: block;
  font-size: 14px;
}

.services ul li a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  text-decoration: underline;
}

.category-description {
  font-size: 12px;
  margin-bottom: 10px;
  color: rgb(100, 100, 100);
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.overview-link {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.overview-link a {
  color: #000000;
  text-decoration: none;
}

.overview-link a:hover {
  text-decoration: underline;
}

.overview-description {
  margin-top: 20px;
  font-size: 12px;
  color: rgb(100, 100, 100);
}
