/* Réinitialisation des marges et des paddings */
body, h1, h2, h3, h4, h5, h6, p, ul, ol, li {
  margin: 0;
  padding: 0;
}

/* Typographie de base */
body {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

h1, h2, h3 {
  font-weight: bold;
}

p {
  margin-bottom: 10px;
}

/* Styles pour les liens */
a {
  color: #007bff; /* Couleur de lien par défaut */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Styles pour les boutons */
.btn {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Styles pour la mise en page */
.container {
  width: 100%;
  max-width: 100%;

}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.col {
  flex: 1;
  padding: 0 10px;
}