.contact-page {
    display: flex;
    justify-content: center;
    padding: 50px 20px; /* Ajout de padding pour éviter que les contenus ne touchent les bords */
  }
  
  .contact-container {
    width: 600px; /* Ajustement de la largeur des conteneurs */
    padding: 30px;
    background: #f9f9f9;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 20px; /* Espace à droite du conteneur de formulaire */
  }
  
  .info-container {
    width: 450px; /* Ajustement de la largeur des conteneurs */
    padding: 30px;
    background: #f9f9f9;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 20px; /* Espace à gauche du conteneur d'informations */
    height: 560px;
  }
  
  .contact-container h2,
  .info-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form {
    margin-top: 20px;
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #45a049;
    box-shadow: 0 0 5px rgba(69, 160, 73, 0.5);
    outline: none;
  }
  
  .contact-form textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contact-form button {
    width: 100%;
    padding: 12px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #333333;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 15px;
    font-size: 0.9em;
  }
  
  .info-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .info-container ul li {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .info-container ul li strong {
    margin-right: 10px;
  }
  

  /* Contact.css */

.success-message {
  color: green;
  margin-top: 50px;
  text-align: center;
}

.error-message {
  color: red;
  margin-top: 50px;
  text-align: center;
}