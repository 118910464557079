.experience-container {
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(228, 228, 228);
    font-size: 3rem; /* Taille du titre */
    margin-bottom: 20px; /* Marge sous le titre */
    font-weight: 700; /* Poids de la police pour le titre */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre pour améliorer la lisibilité */
  }
  
  .tab-banner {
    background-image: url("../../components/assets/experience.jpg"); /* Remplacez par le chemin vers votre image */
    background-size: cover;
    background-position: center;
    height: 200px;
    margin-bottom: 10px;

  }
  
  .tab-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .tab-buttons button {
    background: transparent;
    border: 2px solid #333;
    color: #333;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab-buttons button.active {
    background-color: #333;
    color: #fff;
  }
  
  .clients-section,
  .case-studies-section {
    margin-bottom: 40px;
  }
  
  .clients-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .client-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
  }
  
  .client-logo {
    width: 100px;
    height: auto;
  }
  
  .case-studies-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .case-study-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
  }
  