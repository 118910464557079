/* Conteneur principal */
.our-values-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Titre en haut à gauche avec marge */
.our-values-title {
  color: #007bff;
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0;
  text-align: center; /* Assure le centrage du texte */
}

/* Carrousel centré avec largeur limitée */
.values-carousel {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 20px 0;
}

.carousel-container {
  position: relative;
  width: 80%; /* Limite la largeur du carrousel */
  max-width: 1200px; /* Largeur maximale du carrousel */
  overflow: hidden;
}

.carousel-wrapper {
  overflow: hidden;
}

.carousel-items {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.value-item {
  flex: 1;
  min-width: 100%;
  box-sizing: border-box;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icônes des valeurs */
.value-icon {
  width: 100%; /* Utilisez 100% pour prendre toute la largeur disponible du conteneur */
  height: auto; /* Conserve le ratio d'aspect de l'image */
  margin-bottom: 20px;
}

.icon-image {
  width: 40%; /* Ajuste l'image pour qu'elle occupe toute la largeur du conteneur parent */
  height: 350px; /* Conserve le ratio d'aspect de l'image */
  border-radius: 8px; /* Optionnel : arrondit les coins des images */
  object-fit: contain;
}


.value-content {
  text-align: center;
}

.value-content h2 {
  font-size: 28px;
  color: #222;
  margin-bottom: 10px;
}

.value-content p {
  font-size: 20px;
  color: #555;
}

/* Boutons de navigation */
.carousel-button {
  position: absolute;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  transform: translateY(-50%);
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

/* Styles pour les boutons au survol */
.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Section de l'appel à l'action */
.cta-section {
  text-align: center;
  margin-top: 40px;
}

.cta-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.cta-link:hover {
  text-decoration: underline;
}
